<template>
    <el-dialog v-if="dialog" :visible="visible" :title="field.label + '设置'" @close="closeDialog"
        :close-on-click-modal="false" width="815px">
        <stall-config :field.sync="field" :value.sync="value" @handleSave="saveConfig" :view-tab.sync="viewTab"
            :level="level + 1" :source="source"></stall-config>
        <div slot="footer" v-if="source == 'analyse'">
            <el-button type="default" @click="closeDialog">取消</el-button>
            <el-button type="primary" @click="saveData">保存</el-button>
        </div>
    </el-dialog>
    <div v-else-if="field.type == 'tabs' && !prop" :class="'tabs-item ' + source">
        <el-tabs v-if="field.properties != null || field.fields.some(t => t.type == 'tab')">
            <i :class="field.isShow ? 'el-icon-arrow-down' : 'el-icon-arrow-right'" @click="field.isShow = !field.isShow"
                style="position: absolute;top: -40px;left: 0;cursor: pointer;" />
            <el-tab-pane :label="field.label + '设置'" v-if="field.properties != null">
                <stall-config v-if="field.isShow" :field="field" :value.sync="value" @handleSave="saveConfig"
                    :prop="true"></stall-config>
            </el-tab-pane>
            <el-tab-pane v-for="tab in field.fields.filter(t => t.type == 'tab')" :key="field.prop + tab.prop"
                :label="field.label + tab.label">
                <stall-config v-if="field.isShow" :field="tab" :value.sync="value[tab.prop]" @handleSave="saveConfig"
                    :prop="true"></stall-config>
            </el-tab-pane>
            <el-button type="danger" plain size="mini" class="reset-button" v-if="field.prop != source"
                @click="saveConfig(null, field.prop, true)">重置</el-button>
        </el-tabs>
        <stall-config v-for="tabs in field.fields.filter(t => t.type == 'tabs')" :key="tabs.prop" :field.sync="tabs"
            :value.sync="value[tabs.prop]" @handleSave="saveConfig" :view-tab.sync="viewTab" :level="level + 1"
            :source="source" v-show="viewTab == tabs.prop"></stall-config>
    </div>
    <el-steps direction="vertical" v-loading="loading"
        v-else-if="prop && field.properties != null && field.vueType == 'steps'">
        <el-step v-for="subField in field.properties" :key="subField.prop" :title="subField.label">
            <template slot="description">
                <el-row type="flex" align="middle" v-if="subField.type == 'check_multi_input'" style="width: 750px;;">
                    <el-col :span="1">
                        <el-checkbox v-model="value[subField.prop].enable" />
                    </el-col>
                    <el-col :span="23">
                        <el-tag closable :disable-transitions="true" @close="handleClose(subField.prop, cIndex)"
                            v-for="(cItem, cIndex) in value[subField.prop].values" class="size-tag"
                            :key="subField.prop + cIndex">
                            <el-input class="out-stock" v-if="subField.prop == 'replace'" v-model="cItem.label"></el-input>
                            {{ subField.prop == 'replace' ? "=>" : "" }}
                            <el-input class="out-stock" v-model="cItem.value"></el-input>
                        </el-tag>
                        <el-button class="button-new-tag" size="small" @click="showInput(subField.prop)">+</el-button>
                    </el-col>
                </el-row>
                <el-row type="flex" align="middle" v-else>
                    <el-col :span="24">
                        <stall-config :field.sync="subField" :value.sync="value[subField.prop]" @handleSave="saveConfig"
                            :source="source"></stall-config>
                    </el-col>
                </el-row>
            </template>
        </el-step>
    </el-steps>
    <div class="setting-item" v-else-if="prop && field.isDraggable">
        <div class="setting-item-child" v-for="(subValue, index) in value" :key="index" :draggable="true"
            @dragstart="dragstart(index)" @drop="saveConfig(subValue.prop)" @dragover="dragover($event, index, value)">
            <stall-config :field="subValue" :value.sync="value[index].value" @handleSave="saveConfig"></stall-config><i
                class='el-icon-sort' />
        </div>
    </div>
    <div class="setting-item" v-else-if="prop && field.properties != null">
        <div class="setting-item-child" v-show="isShow(itemField)" v-for="itemField in field.properties"
            :key="itemField.prop">
            <stall-config :field="itemField" :value.sync="value[itemField.prop]" @handleSave="saveConfig"
                :source="source"></stall-config>
        </div>
    </div>
    <span v-else>
        <span v-if="field.type != 'check' && field.type != 'multi_check'">{{ field.label }}：</span>
        <template v-if="field.type == 'multi_check'">
            <el-checkbox v-for="rItem in field.options" :label="rItem.label" :key="rItem.prop"
                v-model="changValue[rItem.prop]" />
        </template>
        <el-checkbox v-if="field.type == 'check'" :label="field.label" v-model="changValue" />
        <el-radio-group v-else-if="field.type == 'redio'" v-model="changValue">
            <el-radio v-for="option in field.options" :label="option.value" :key="option.value">
                {{ option.label }}</el-radio>
        </el-radio-group>
        <el-switch v-else-if="field.type == 'switch' || (typeof value === 'boolean' && !field.type)"
            v-model="changValue"></el-switch>
        <el-select v-else-if="field.type == 'select'" v-model="changValue" :style="getStyle(field.styles)">
            <el-option v-for="option in field.options" :key="option.value" :value="option.value"
                :label="option.label"></el-option>
        </el-select>
        <el-input v-else-if="field.type == 'input'" v-model="changValue" :style="getStyle(field.styles)"></el-input>
        <el-input-number v-else-if="field.type == 'number_input'" v-model="changValue" :step="1" :min="12"
            :style="getStyle(field.styles)"></el-input-number>
        {{ field.labelEnd }}
    </span>
</template>

<script>
import { submit } from '@/api/base';
import axios from "axios";
export default {
    name: "StallConfig",
    props: {
        //配置项
        field: { type: Object },
        //配置项对应值
        value: {},
        level: { type: Number },
        //来源
        source: { type: String, default: 'print' },
        viewTab: { type: String, default: 'bill' },
        dragIndex: { type: Number },
        dialog: { type: Boolean },
        prop: { type: Boolean }
    },
    computed: {
        changValue: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.value != value) {
                    this.$emit('update:value', value);
                    this.$emit('handleSave', this.field.prop);
                }
            }
        },
    },
    data() {
        return {
            visible: false,
            loading: false,
            defaultValue: null,
            activeFields: {}
        }
    },
    methods: {
        showDialog() {
            if (this.field.prop != this.source) {
                this.getConfig();
            }
            this.visible = true;
        },
        closeDialog() {
            this.visible = false;
        },
        isShow(itemField) {
            if (itemField.isHidden) {
                return false;
            }
            if (itemField.depends) {
                for (let i = 0; i < itemField.depends.length; i++) {
                    let dep = itemField.depends[i];
                    if (this.value[dep.prop] != dep.value) {
                        return false;
                    }
                }
            }
            return true;
        },
        getStyle(styles) {
            let style = '';
            for (let attr in styles) {
                style += `${attr}:${styles[attr]};`;
            }
            return style;
        },
        dragstart(index) {
            this.field.dragIndex = index;
        },
        dragover(e, index, data) {
            e.preventDefault();
            if (this.field.dragIndex !== index) {
                let item = data[this.field.dragIndex];
                data.splice(this.field.dragIndex, 1);
                data.splice(index, 0, item);
                this.field.dragIndex = index;
            }
        },
        async getConfig() {
            let newValue = {};
            await axios.get(`./static/json/stall/default.json`).then((json) => {
                this.defaultValue = json.data[this.source];
                newValue = JSON.parse(JSON.stringify(this.defaultValue));
            });
            const { data } = await submit('/api/stall/getConfig', { type: this.source });
            newValue = this.parseValue(data, newValue);

            let isupdate = newValue.isupdate;
            delete newValue.isupdate;
            if (isupdate) {
                await submit('/api/stall/saveConfig', { type: this.source, config: newValue });
            }
            this.$emit('update:value', newValue);
            if (newValue.printView) {
                this.$emit('update:view-tab', newValue.printView);
            }

            await axios.get(`./static/json/stall/${this.source}.json`).then((json) => {
                if (json.data.properties) {
                    json.data.isShow = true;
                }
                if (json.data.fields) {
                    json.data.fields.forEach(field => {
                        field.isShow = true;
                    });
                }
                this.$emit('update:field', json.data);
            });
        },
        parseValue(configValue, defaultValue) {
            let result = { isupdate: false };
            if (Object.keys(configValue).length == 0) {
                result = defaultValue;
                result.isupdate = true;
                return result;
            }
            for (let item in configValue) {
                if (defaultValue[item] != null) {
                    if (Array.isArray(defaultValue[item])) {
                        if (defaultValue[item].length > 0) {
                            let itemValue = [];
                            configValue[item].forEach(c => {
                                let dItem = defaultValue[item].filter(t => t.prop == c.prop);
                                if (dItem.length > 0) {
                                    itemValue.push(c);
                                }
                                else {
                                    result.isupdate = true;
                                }
                            });
                            defaultValue[item].forEach(d => {
                                let dItem = configValue[item].filter(t => t.prop == d.prop);
                                if (dItem.length == 0) {
                                    result.isupdate = true;
                                    itemValue.push(d);
                                }
                            });
                            result[item] = itemValue;
                        }
                        else {
                            result[item] = configValue[item];
                        }
                    }
                    else if (typeof defaultValue[item] === 'object') {
                        result[item] = this.parseValue(configValue[item], defaultValue[item]);
                        if (result[item].isupdate) {
                            result.isupdate = result[item].isupdate;
                        }
                        delete result[item].isupdate;
                    }
                    else {
                        result[item] = configValue[item];
                    }
                }
            }
            for (let item in defaultValue) {
                if (configValue[item] == null) {
                    result.isupdate = true;
                    result[item] = defaultValue[item];
                }
            }
            return result;
        },
        async saveConfig(key, view = null, reset = false) {
            if (this.level != 0) {
                this.$emit('handleSave', key, view, reset);
                return;
            }
            let postData = {
                type: this.source,
                config: this.value
            };
            if (reset) {
                let newValue = JSON.parse(JSON.stringify(this.defaultValue));
                if (view) {
                    postData.config[view] = newValue[view];
                }
                else {
                    postData.config = newValue;
                }
            }
            await submit('/api/stall/saveConfig', postData);
            if (reset) {
                this.$baseMessage('重置成功', 'success');
                this.$emit('update:value', postData.config);
            }
            this.$emit('handleSave', key);
        },
        showInput(key) {
            var length = this.value[key].values.length;
            if (length > 0) {
                if (key != 'replace' && this.value[key].values[length - 1].value == '') {
                    this.$baseMessage("请先填写前一项内容", "error");
                    return;
                }
                if (key == 'replace' && this.value[key].values[length - 1].label == '') {
                    this.$baseMessage("请先填写前一项替换内容", "error");
                    return;
                }
            }
            this.value[key].values.push({ label: '', value: '' });
        },
        handleClose(key, index) {
            this.value[key].values.splice(index, 1);
        },
        saveData() {
            if (this.value.filter) {
                let flength = this.value.filter.values.length;
                if (flength > 0 && this.value.filter.values[flength - 1].value == '') {
                    this.value.filter.values.splice(flength - 1, 1);
                }
            }
            if (this.value.convert) {
                let clength = this.value.convert.values.length;
                if (clength > 0 && this.value.convert.values[clength - 1].value == '') {
                    this.value.convert.values.splice(clength - 1, 1);
                }
            }
            if (this.value.replace) {
                let rlength = this.value.replace.values.length;
                if (rlength > 0 && this.value.replace.values[rlength - 1].label == '') {
                    this.value.replace.values.splice(rlength - 1, 1);
                }
            }
            this.saveConfig();
            this.closeDialog();
        }

    }
}
</script>

<style lang="scss" scoped>
.tabs-item {
    ::v-deep {
        .el-tabs__header {
            margin-left: 20px;
        }

        .el-tabs__content {
            overflow: visible;
        }

        .el-tabs__item {
            padding: 0 10px;
        }
    }
}

.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.size-tag {
    font-size: 14px;
    height: 30px;
    margin: 2px 10px;


    .out-stock {
        width: 60px;
        padding: 0 6px;

        ::v-deep {
            .el-input__inner {
                height: 25px !important;
                vertical-align: middle;
                padding-left: 5px;
            }
        }
    }
}

.reset-button {
    height: 22px;
    padding: 0 10px;
    position: absolute;
    top: -45px;
    right: 10px;
}

.setting-item {
    background: #a3a3a3;
    padding: 5px 5px 0 5px;
    font-size: 13px;
}

.setting-item-child {
    // 设置 动画效果
    transition: transform .3s;
    text-align: left;
    cursor: move;
    min-width: 250px;
    display: inline-block;
    background: #fff;
    border-radius: 5px;
    padding: 5px 5px 5px 10px;
    height: 42px;
    line-height: 32px;
    box-sizing: border-box;
    margin: 0 5px 5px 0;

    .el-icon-sort {
        position: absolute;
        right: 20px;
        font-size: 20px;
        line-height: 30px;
    }

    ::v-deep {
        .el-radio {
            margin-right: 10px;

            .el-radio__label {
                padding-left: 3px;
            }
        }
    }
}
</style>