<template>
  <el-container>
    <el-aside width="580px">
      <el-container>
        <el-header height="40px">
          <el-row>
            <el-col :span="8">
              <el-date-picker v-model="queryData.dateRange" type="daterange" value-format="yyyy-MM-dd"
                :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                align="right" :editable="false" :clearable="false" @change="query" />
            </el-col>
            <el-col :span="8">
              <el-select v-model="queryParam.searchField">
                <el-option value="Market" label="市场"></el-option>
                <el-option value="SupplierName" label="供应商"></el-option>
                <el-option value="PurchaserName" label="客户"></el-option>
                <el-option value="ItemNo" label="货号"></el-option>
              </el-select>
              <el-input v-model="queryParam.searchValue" @clear="query" @keydown.enter.native="query"
                clearable></el-input>
            </el-col>
            <el-col :span="4">
              <el-button type="primary" icon="el-icon-refresh" @click="query">查询</el-button>
            </el-col>
            <el-col :span="4">
              <el-switch v-model="singleSwitch" inactive-text="单选" />
            </el-col>
          </el-row>
        </el-header>
        <el-main>
          <grid-table ref="gridTable" :tableConfig="tableConfig" :showIndexColumn="false" :showLastEmptyColumn="false"
            :showPagination="false" @selectionChange="selectionChange" @handleSelect="handleSelect"
            @handleSelectAll="handleSelectAll" @handleRowClick="handleRowClick">
            <template slot="NumInfo" slot-scope="{ params }">
              {{ params.row[params.column.props[0]] + (params.row[params.column.props[1]] == 0 ? "" : "(" +
                (params.row[params.column.props[1]] > 0 ? "缺" : "加") + Math.abs(params.row[params.column.props[1]]) + ")")
              }}
            </template>
            <template slot="PurchaserInfo" slot-scope="{ params }">
              {{ params.row[params.column.props[0]] + (params.row[params.column.props[1]] == "" ? "" : "(" +
                params.row[params.column.props[1]] + ")") }}
            </template>
          </grid-table>
        </el-main>
        <el-footer height="30px">
          <span>供应商：{{ statistics.supplierCount }}</span>
          <span>客户数：{{ statistics.purchaserCount }}</span>
          <span>订单数：{{ statistics.preorderCount }}</span>
          <span>商品数：{{ statistics.totalCount }}</span>
          <span>退货数：{{ statistics.totalRefundCount }}</span>
        </el-footer>
      </el-container>
    </el-aside>
    <el-main>
      <el-container id="print-view">
        <el-header height="40px">
          <el-tabs v-model="printInfo.type" @tab-click="changePrintViewTab">
            <el-tab-pane v-for="field in getConfigFields()" :key="field.prop" :label="'按' + field.label + '分组'"
              :name="field.prop"></el-tab-pane>
          </el-tabs>
        </el-header>
        <el-main>
          <div class="print-view-left">
            <div :class="{ 'is-active': printInfo.activeTab == 0 }" @click="printInfo.activeTab = 0; dealSelected();">
              全部
            </div>
            <div :class="{ 'is-active': printInfo.activeTab == 1 }" @click="printInfo.activeTab = 1; dealSelected();">
              已提货
            </div>
            <div :class="{ 'is-active': printInfo.activeTab == 2 }" @click="printInfo.activeTab = 2; dealSelected();">
              未提货
            </div>
            <div :class="{ 'is-active': printInfo.activeTab == 3 }" @click="printInfo.activeTab = 3; dealSelected();">
              退货
            </div>
            <div :class="{ 'is-active': printInfo.activeTab == 4 }" @click="printInfo.activeTab = 4; dealSelected();">
              缺货
            </div>
          </div>
          <div class="print-view-main">
            <div id="printArea-supplier" class="paper-wrapper paper-supplier" v-show="printInfo.type == 'supplier'">
            </div>
            <div id="printArea-purchaser" class="paper-wrapper paper-purchaser" v-show="printInfo.type == 'purchaser'">
            </div>
            <div id="printArea-store" class="paper-wrapper paper-store" v-show="printInfo.type == 'store'">
            </div>
          </div>
        </el-main>
        <el-footer height="40px">
          <el-button type="primary" v-print="printNB">打印</el-button>
          <el-button type="primary" @click="excel" :loading="loading">导出</el-button>
        </el-footer>
      </el-container>
    </el-main>
    <el-aside width="280px">
      <stall-config ref="reportConfig" :field.sync="configField" :value.sync="configValue" @handleSave="dealSelected"
        :view-tab.sync="printInfo.type" :level="0" source="report">
      </stall-config>
    </el-aside>
    <helper-center ref="helperCenter" position="trade_data" :visible="true"></helper-center>
  </el-container>
</template>

<script>
import dayjs from "dayjs";
import Print from "vue-print-nb";
import { submit, exportFile } from "@/api/base";
import { getDate, getColumnValue, getDatePickerOptions } from "@/utils/tool";
import { floatFormat } from "@/utils/validate";
import StallConfig from "@/views/stall/trade/components/StallConfig.vue";
import HelperCenter from "@/views/stall/trade/components/HelperCenter.vue";
export default {
  name: "StallDataForm",
  directives: { Print },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
  components: {
    StallConfig,
    HelperCenter,
  },
  data() {
    return {
      loading: false,
      singleSwitch: false,
      queryParam: {
        state: 1,
        searchField: "SupplierName",
        searchValue: "",
        startTime: "",
        endTime: "",
      },
      queryData: {
        dateRange: [getDate(), getDate()],
      },
      pickerOptions: getDatePickerOptions(),
      clickRow: null,
      selectRows: [],
      tableConfig: {
        url: "/api/StallStatistics/getList",
        columns: [
          {
            label: "供应商",
            prop: "SupplierName",
            type: "text",
            sortType: true,
            width: "100px",
          },
          {
            label: "客户",
            prop: "PurchaserInfo",
            sortType: true,
            props: ["PurchaserName", "PurchaserNick"],
            type: "custom-operation",
            width: "180px",
          },
          {
            label: "订单数",
            prop: "OrderTotal",
            type: "text",
            width: "80px",
          },
          {
            label: "商品数",
            prop: "NumInfo",
            props: ["ItemTotal", "OutStockTotal"],
            type: "custom-operation",
            width: "80px",
          },
          {
            label: "退货数",
            prop: "RefundTotal",
            type: "text",
            width: "80px",
          },
        ],
      },
      statistics: {
        supplierCount: 0,
        purchaserCount: 0,
        preorderCount: 0,
        totalCount: 0,
        totalRefundCount: 0,
      },
      printInfo: {
        type: "supplier",
        activeTab: 0,
        supplierdata: [],
        purchaserData: [],
        storeData: [],
        pxPerMm: null,
        pageTotal: 0,
        paperWidth: 0,
        paperHeight: 0,
      },
      printNB: {
        id: "printArea-supplier",
        clickMounted(e) {
          e.$(`#print-view .paper-supplier .print-a4`).css("padding", 0);
          e.$(`#print-view .paper-supplier .print-a4`).css("margin-top", 0);
          e.$(`#print-view .paper-purchaser .print-a4`).css("padding", 0);
          e.$(`#print-view .paper-purchaser .print-a4`).css("margin-top", 0);
          e.$(`#print-view .paper-store .print-a4`).css("padding", 0);
          e.$(`#print-view .paper-store .print-a4`).css("margin-top", 0);
        },
        closeCallback(e) {
          e.$(`#print-view .paper-supplier .print-a4`).css("padding", "4mm");
          e.$(`#print-view .paper-supplier .print-a4`).css(
            "margin-top",
            "10px"
          );
          e.$(`#print-view .paper-purchaser .print-a4`).css("padding", "4mm");
          e.$(`#print-view .paper-purchaser .print-a4`).css(
            "margin-top",
            "10px"
          );
          e.$(`#print-view .paper-store .print-a4`).css("padding", "4mm");
          e.$(`#print-view .paper-store .print-a4`).css(
            "margin-top",
            "10px"
          );
        },
      },
      dragIndex: "",
      configField: {},
      configValue: {},
    };
  },
  methods: {
    getConfigFields() {
      var fields = this.configField.fields ?? [];
      if (this.$store.state.routes.systemSetting.MultiStore) {
        return fields;
      }
      return fields.filter(x => x.prop != 'store');
    },
    async init() {
      await this.$refs.reportConfig.getConfig();
      this.query();
    },
    async query() {
      this.queryParam.startTime = this.queryData.dateRange[0] + " 00:00:00";
      this.queryParam.endTime = this.queryData.dateRange[1] + " 23:59:59";
      this.queryParam.groupType = this.printInfo.type;
      this.$refs.gridTable.fetchData(false, this.getStatistics, this.queryParam);

      this.printInfo.supplierData = [];
      this.printInfo.purchaserData = [];
      this.printInfo.storeData = [];
      this.setPrintContent(this.printInfo.supplierData, "supplier");
      this.setPrintContent(this.printInfo.purchaserData, "purchaser");
      this.setPrintContent(this.printInfo.storeData, "store");
    },
    getStatistics(datas) {
      this.selectRows = [];

      this.statistics.supplierCount = 0;
      this.statistics.preorderCount = 0;
      this.statistics.purchaserCount = 0;
      this.statistics.totalCount = 0;
      this.statistics.totalRefundCount = 0;

      let _this = this;
      let suppliers = [];
      let purchasers = [];
      datas.forEach(function (data) {
        if (!suppliers.includes(data.SupplierName)) {
          suppliers.push(data.SupplierName);
          _this.statistics.supplierCount += 1;
        }

        const arr = data.PurchaserName.split(",");
        for (const item of arr) {
          if (!purchasers.includes(item)) {
            purchasers.push(item);
            _this.statistics.purchaserCount += 1;
          }
        }

        _this.statistics.preorderCount += data.OrderTotal;
        _this.statistics.totalCount += data.ItemTotal - data.OutStockTotal;
        _this.statistics.totalRefundCount += data.RefundTotal;
      });
    },
    selectionChange(selection) {
      this.selectRows = selection;
      if (this.clickRow) {
        this.dealSelectRow(this.clickRow);
      }
    },
    //列表选中框选择，
    handleSelect(selection, row) {
      this.clickRow = row;
    },
    handleSelectAll(selection) {
      this.dealSelected();
    },
    //点击行触发
    handleRowClick(row) {
      //触发selection-change绑定事件changeSelection
      this.$refs.gridTable.$refs.gridTable.toggleRowSelection(row);
      //处理选中行自定义事件
      this.dealSelectRow(row);
    },
    dealSelectRow(row) {
      this.clickRow = null;
      if (!this.singleSwitch) {
        let selected = false;
        if (this.selectRows.some((data) => data.SupplierID == row.SupplierID && data.PurchaserID == row.PurchaserID)) {
          selected = true;
        }
        const _this = this;
        this.$refs.gridTable.tableData.forEach(function (data) {
          if (data.SupplierID != row.SupplierID || data.PurchaserID != row.PurchaserID) {
            if ((_this.printInfo.type == "supplier" && row.SupplierName == data.SupplierName)
              || (_this.printInfo.type == "purchaser" && row.PurchaserName == data.PurchaserName)) {
              if (selected) {
                _this.$refs.gridTable.$refs.gridTable.toggleRowSelection(data, true);
              } else {
                _this.$refs.gridTable.$refs.gridTable.toggleRowSelection(data, false);
              }
            }
          }
        });
      }
      this.dealSelected();
    },
    async dealSelected(isChange = true) {
      if (isChange) {
        this.printInfo.supplierData = [];
        this.printInfo.purchaserData = [];
        this.printInfo.storeData = [];
      }
      var detailIDs = [];
      var refundDetailIDs = [];
      this.selectRows.forEach((item) => {
        item.DetailIDs.forEach((id) => { detailIDs.push(id); });
        item.RefundDetailIDs.forEach((id) => { refundDetailIDs.push(id); });
      });
      if (detailIDs.length == 0 && refundDetailIDs.length == 0) {
        this.printInfo.supplierData = [];
        this.printInfo.purchaserData = [];
        this.printInfo.storeData = [];
        this.setPrintContent([], "supplier");
        this.setPrintContent([], "purchaser");
        this.setPrintContent([], "store");
        return;
      }

      let condition = {
        DetailIDs: detailIDs,
        RefundDetailIDs: refundDetailIDs,
        GroupType: this.printInfo.type,
        SubType: this.printInfo.activeTab,
      };

      const res = await submit("/api/StallStatistics/GetDetails", condition);
      if (this.printInfo.type == "supplier") {
        this.printInfo.supplierData = res.data;
        this.setPrintContent(this.printInfo.supplierData, "supplier");
      } else if (this.printInfo.type == "store") {
        this.printInfo.storeData = res.data;
        this.setPrintContent(this.printInfo.storeData, "store");
      } else {
        this.printInfo.purchaserData = res.data;
        this.setPrintContent(this.printInfo.purchaserData, "purchaser");
      }
    },
    changePrintViewTab() {
      this.printNB.id = `printArea-${this.printInfo.type}`;
      if ((this.printInfo.type == "supplier" && this.printInfo.supplierData.length == 0)
        || (this.printInfo.type == "purchaser" && this.printInfo.purchaserData.length == 0)
        || (this.printInfo.type == "store" && this.printInfo.storeData.length == 0)
      ) {
        this.dealSelected(false);
      }
    },
    async excel() {
      var detailIDs = [];
      var refundDetailIDs = [];
      this.selectRows.forEach((item) => {
        item.DetailIDs.forEach((id) => { detailIDs.push(id); });
        item.RefundDetailIDs.forEach((id) => { refundDetailIDs.push(id); });
      });
      if (detailIDs.length == 0 && refundDetailIDs.length == 0) {
        this.printInfo.supplierData = [];
        this.printInfo.purchaserData = [];
        this.printInfo.storeData = [];
        this.setPrintContent(this.printInfo.supplierData, "supplier");
        this.setPrintContent(this.printInfo.purchaserData, "purchaser");
        this.setPrintContent(this.printInfo.storeData, "store");
        return;
      }

      this.loading = true;
      let condition = {
        DetailIDs: detailIDs,
        RefundDetailIDs: refundDetailIDs,
        GroupType: this.printInfo.type,
        SubType: this.printInfo.activeTab,
      };
      const { data } = await exportFile("/api/StallStatistics/Export", condition).finally(() => {
        this.loading = false;
      });

      const date = dayjs(this.queryParam.startTime).format("YYYY-MM-DD") + "至" + dayjs(this.queryParam.endTime).format("YYYY-MM-DD");
      const fileName = this.printInfo.type == "supplier" ? `${date}供应商表` : (this.printInfo.type == 'store' ? `${date} 档口表` : `${date} 客户表`);

      const blob = new Blob([data.Blob], { type: "application/vnd.ms-excel" });
      var a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = fileName;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    getOneMmsPx() {
      let div = document.createElement("div");
      div.id = "mm";
      div.style.width = "1mm";
      document.querySelector("body").appendChild(div);
      const mm = document.getElementById("mm").getBoundingClientRect();
      this.printInfo.pxPerMm = mm.width;
      div.remove();
    },
    getPrintStyle() {
      if (!this.printInfo.pxPerMm) this.getOneMmsPx();
      this.printInfo.paperWidth = 210 * this.printInfo.pxPerMm;
      this.printInfo.paperHeight = 297 * this.printInfo.pxPerMm;

      let style = '<style type="text/css">';

      style += ".print-a4 {";
      style += "position: relative;";
      style += "font-size: 16px;";
      style += "padding: 4mm;";
      style += "margin-top:10px;";
      style += `width: ${this.printInfo.paperWidth}px;`;
      style += `height: ${this.printInfo.paperHeight}px;`;
      style += "background: white;";
      style += "page-break-after: always;";
      style += "}";

      style += ".print-title{";
      style += "font-size: 20px;";
      style += "font-weight: bold;";
      style += "text-align: center;";
      style += "}";

      style += ".print-page-info{";
      style += "position: absolute;";
      style += "top: 3mm;";
      style += "right: 3mm;";
      style += "}";

      style += ".print-table-wrapper{";
      style += "padding-top: 10px;";
      style += "}";

      style += ".print-table{";
      style += "width:100%;";
      style += "border-collapse:collapse;";
      style += "}";

      style += ".print-table-center{";
      style += "text-align: center;";
      style += "}";

      style += ".print-table-red{";
      style += "color: red;";
      style += "}";

      style += "table td{";
      style += "padding:2px;";
      style += "}";

      style += "</style>";
      return style;
    },
    setPrintContent(data, type, page = 1) {
      const $paperWrapper = this.$(`#print-view .paper-${type}`);
      if (page == 1) {
        $paperWrapper.html("");
        $paperWrapper.append(this.getPrintStyle());
      }

      let $printPage = this.$(`<div class="print-a4 print-wrapper-${type} print-page-${page}"></div>`);
      $paperWrapper.append($printPage);
      $printPage.append(`<div class="print-content"></div>`);
      let $content = $printPage.find(".print-content");

      const date = dayjs(this.queryParam.startTime).format("YYYY年MM月DD日");
      const printTitle = type == "supplier" ? `${date} 供应商表` : (type == 'store' ? `${date} 档口表` : `${date} 客户表`);
      $content.append(`<div class="print-title">${printTitle}</div>`);

      $content.append(`<div class="print-page-info">${page}/<span class="print-page-total"></span>页</div>`);
      this.$(`#print-view .paper-${type} .print-page-total`).text(page);

      $content.append('<div class="print-table-wrapper"></div>');
      let $tableWrapper = $content.find(".print-table-wrapper");

      $tableWrapper.append(
        '<table class="print-table" align="center" border=1 cellspacing=0 cellpadding=0></table>'
      );
      let $table = $tableWrapper.find("table");

      let $tr = this.$("<tr></tr>");

      var groupField = this.configField.fields.filter((t) => t.prop == type)[0];
      let marketFields = this.configValue[type].columns.filter((t) => t.prop == "Market" && t.value);
      let marketEnable = false;
      if (marketFields && marketFields.length > 0) {
        marketEnable = true;
        $tr.append(`<th>${marketFields[0].label}</th>`);
      }
      $tr.append(`<th style="min-width:70px;">${groupField.label}</th>`);

      this.configValue[type].columns.forEach((item) => {
        if (item.value && item.prop != "Market") {
          $tr.append(`<th>${item.label}</th>`);
        }
      });
      $table.append($tr);

      let rowIndex = 0;
      let hasNext = false;
      data = JSON.parse(JSON.stringify(data));

      let marketIndex = 0;
      let groupIndex = 0;
      let groupCountIndex = 0;
      let supplierIndex = 0;
      let itemIndex = 0;
      let groupCountEnable = false;
      let supplierEnable = false;
      let itemEnable = false;
      let costSubFeeEnable = false;
      let settleSubFeeEnable = false;
      for (let i = 0; i < data.length; i++) {
        const item = data[i];

        const clsx = i == 0 && page == 1 ? "print-table-center print-table-red" : "";
        const cls2 = i == 0 && page == 1 ? "print-table-center print-table-red" : "print-table-center";

        let $tr = this.$(`<tr clase="${page}-${i}"></tr>`);
        $tr.append(`<td class="sub-id-${page}" style="display:none;">${item.GroupID}</td>`);
        if (marketEnable) {
          $tr.append(`<td class="${clsx} group-market-${page}-${i}">${item.Market || "-"}</td>`);
        }
        $tr.append(`<td class="${clsx} group-name-${page}-${i}">${item.GroupName}</td>`);

        this.configValue[type].columns.forEach((column) => {
          if (column.value) {
            switch (column.prop) {
              case "GroupCount":
                groupCountEnable = true;
                $tr.append(`<td class="${cls2} group-count-${page}-${i}">${item.GroupCount || "-"}</td>`);
                break;
              case "SubCount":
                supplierEnable = true;
                $tr.append(`<td class="${cls2} sub-count-${page}-${i}">${item.SubCount || "-"}</td>`);
                break;
              case "CostSubFee":
                costSubFeeEnable = true;
                $tr.append(`<td class="${cls2} cost-sub-fee-${page}-${i}">${item.CostSubFee || "-"}</td>`);
                break;
              case "SettleSubFee":
                settleSubFeeEnable = true;
                $tr.append(`<td class="${cls2} settle-sub-fee-${page}-${i}">${item.SettleSubFee || "-"}</td>`);
                break;
              case "ItemNo":
                itemEnable = true;
                $tr.append(`<td class="${cls2} itemno-${page}-${i}">${item.ItemNo || "-"}</td>`);
                break;
              case "Detail":
                $tr.append(`<td class="${clsx} ${item.Detail ? "" : "print-table-center"}">${item.Detail || "-"}</td>`);
                break;
              case "Market":
                break;
              default:
                $tr.append(`<td class="${cls2}">${item[column.prop] || "-"}</td>`);
                break;
            }
          }
        });
        $table.append($tr);
        rowIndex = i; //行号

        const maxPageHeight = parseFloat($printPage.height());
        const contentHeight = parseFloat($content.outerHeight(true));
        if (maxPageHeight - contentHeight <= 0) {
          $table.find("tr:last").remove();
          hasNext = true;
          break;
        }
        if (data[i].Market != data[marketIndex].Market) {
          marketIndex = i;
          groupIndex = i;
          groupCountIndex = i;
          supplierIndex = i;
          itemIndex = i;
        }
        else if (data[i].GroupID != data[groupIndex].GroupID) {
          groupIndex = i;
          groupCountIndex = i;
          supplierIndex = i;
          itemIndex = i;
        } else if (data[i].Type != data[groupCountIndex].Type) {
          groupCountIndex = i;
          supplierIndex = i;
          itemIndex = i;
        } else if (
          type == "purchaser" &&
          data[i].SubCount != data[supplierIndex].SubCount
        ) {
          supplierIndex = i;
          itemIndex = i;
        } else if (data[i].ProductID != data[itemIndex].ProductID) {
          itemIndex = i;
        }

        if (marketEnable && i != marketIndex) {
          this.$(`#print-view .paper-${type} .group-market-${page}-${marketIndex}`)[0].rowSpan += 1;
          this.$(`#print-view .paper-${type} .group-market-${page}-${i}`)[0].style.display = "none";
        }
        if (i != groupIndex) {
          this.$(`#print-view .paper-${type} .group-name-${page}-${groupIndex}`)[0].rowSpan += 1;
          this.$(`#print-view .paper-${type} .group-name-${page}-${i}`)[0].style.display = "none";

          if (costSubFeeEnable) {
            this.$(`#print-view .paper-${type} .cost-sub-fee-${page}-${groupIndex}`)[0].rowSpan += 1;
            this.$(`#print-view .paper-${type} .cost-sub-fee-${page}-${i}`)[0].style.display = "none";
          }
          if (settleSubFeeEnable) {
            this.$(`#print-view .paper-${type} .settle-sub-fee-${page}-${groupIndex}`)[0].rowSpan += 1;
            this.$(`#print-view .paper-${type} .settle-sub-fee-${page}-${i}`)[0].style.display = "none";
          }
        }
        if (groupCountEnable && i != groupCountIndex) {
          this.$(`#print-view .paper-${type} .group-count-${page}-${groupCountIndex}`)[0].rowSpan += 1;
          this.$(`#print-view .paper-${type} .group-count-${page}-${i}`)[0].style.display = "none";
        }

        if (type == "purchaser" && i != supplierIndex && supplierEnable) {
          this.$(`#print-view .paper-${type} .sub-count-${page}-${supplierIndex}`)[0].rowSpan += 1;
          this.$(`#print-view .paper-${type} .sub-count-${page}-${i}`)[0].style.display = "none";
        }
        if (i != itemIndex && itemEnable) {
          this.$(`#print-view .paper-${type} .itemno-${page}-${itemIndex}`)[0].rowSpan += 1;
          this.$(`#print-view .paper-${type} .itemno-${page}-${i}`)[0].style.display = "none";
        }
      }

      if (hasNext) {
        data = data.slice(rowIndex);
        this.setPrintContent(data, type, page + 1);
      }
    },
  },
};
</script>

<style>
@media print {
  @page {
    size: auto;
    margin: 4mm;
  }
}
</style>

<style lang="scss" scoped>
::v-deep {
  .el-aside {
    .el-col {
      .el-switch {
        margin-top: 5px;
      }

      .el-range-editor {
        width: 190px;
        padding: 3px 5px;

        .el-range__close-icon {
          width: 5px;
        }
      }

      .el-select {
        width: 90px;
        margin-right: 5px;
      }

      .el-input {
        width: 90px;
      }
    }

    .el-footer {
      font-size: 15px;
      font-weight: bold;
      padding-top: 8px;

      span {
        display: inline-block;
        width: 20%;
      }
    }
  }

  #print-view {
    .el-main {
      position: relative;

      .print-view-main {
        width: 100%;
        height: 100%;
        background: #a3a3a3;
        position: relative;
        box-sizing: border-box;
        overflow: auto;

        .paper-wrapper {
          min-height: 100%;
          display: flex;
          align-content: center;
          justify-content: center;
          flex-wrap: wrap;
          padding-bottom: 10px;
        }
      }

      .print-view-left {
        position: absolute;
        top: 10px;
        left: 0;
        z-index: 99;

        >div {
          background: white;
          margin-bottom: 5px;
          height: 32px;
          width: 65px;
          line-height: 32px;
          text-align: center;
          border: 1px solid white;
          border-left: none;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          cursor: pointer;

          &.is-active {
            color: #409eff;
            border-color: #409eff;
          }
        }
      }
    }

    .el-footer {
      text-align: right;
      line-height: 40px;
      vertical-align: middle;
    }
  }
}
</style>
